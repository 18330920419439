body {
  background-color: #252525;
  color: #c1c1c1;
  font-family: 'Nunito', sans-serif;
}

.center {
  position: absolute;
  top: 50%;
  left: calc(50% - 35px);
  margin-left: -(X/2) u;
  margin-top: -(Y/2) u;
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.center-horizontally {
  text-align: center;
  font-size: 20px;
  margin: auto;
}

p {
  font-weight: 200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #c1c1c1;
}

h1 {
  font-size: 42px;
  text-transform: uppercase;
  font-weight: 200;
}

h2 {
  font-size: 36px;
  font-weight: 200;
  text-transform: uppercase;
}

h3 {
  font-size: 30px;
  font-weight: 200;
}

h4 {
  font-size: 24px;
  font-weight: 200;
}

h5 {
  font-size: 18px;
  font-weight: 200;
}

hr {
  border: 1px solid #cfcfcf;
}

a {
  text-decoration: none;
  color: #a1bad5;
  outline: none;
}

a:hover {
  color: #5f7c9c;
  text-decoration: none;
}

.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  //background-image: url("/assets/images/max_ammersee.jpg");
  background-image: url("/assets/images/max_eva.jpeg");
  filter: grayscale(100%);
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/* =====
 * Intro
 * =====
 */

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.intro {
  height: 100vh; /* 100% of screen */
  position: relative;
}

.intro .intro-content {
  text-align: center;
  position: absolute;
}

.intro .intro-title {
  font-size: 4rem;
  //color: #000000;
  color: #c1c1c1;
  font-weight: 300;
  margin: 0;
}

.intro .intro-subtitle {
  font-size: 2.5rem;
  font-weight: 200;
  color: #c1c1c1;
}

/* ===============
 * Language Picker
 * ===============
 */

.language-picker {
  display: flex;
  flex-direction: row;
  filter: grayscale(60%);
}

.language-picker button:hover {
  background: unset !important;
}

.language-picker-mobile {
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.language-picker-mobile:hover {
  background-color: unset !important;
}

.language-picker-button {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.language-picker-flag-container {
  position: relative;
}

.language-picker-flag {
  height: 30px;
  padding-right: 5px;
}

.language-picker-checkmark-visible {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  opacity: 0.9;
}

.language-picker-checkmark-invisible {
  display: none;
}

.language-picker-flag:hover {
  opacity: 0.5;
  cursor: pointer;
}

.language-picker-checkmark-visible:hover {
  opacity: 0.5;
  cursor: pointer;
}

/* ==============
 * Navigation Bar
 * ==============
 */

.navbar {
  width: calc(100% - 20rem);
  padding-top: 0.5rem;
  padding-left: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 5;
}

.navbar--sticky {
  background: #1c1c1c;
  /* background: #333333; */
  position: fixed;
  padding: 0.5rem 10rem;
  top: 0;
  left: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow: 1px 2px 1px #222222; */
  animation: moveDown 0.5s ease-in-out;
}

.navbar--logo-holder {
  display: flex;
  align-items: center;
  height: 30px;
  filter: grayscale(60%);
}

.navbar--logo-holder:hover {
  cursor: pointer;
}

.navbar--logo {
  height: 100%;
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--logo-text {
  color: #c1c1c1;
}
.navbar--logo-text, .navbar--logo-text-sticky {
  font-weight: 200;
}

.navbar--logo-text-sticky {
  color: #c1c1c1;
  margin: 0;
}

.navbar--link {
  display: flex;
}

.active-page:before {
  margin-right: 5rem;
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  background: #c1c1c1;
  height: 1px;
  left: 0;
  right: 0;
  width: 100%;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.active-page:focus:before {
  left: 0;
  right: auto;
  width: 100%;
  animation: moveRight, 0.3s, ease-in-out, 0.3s;
}

.navbar--link-item {
  color: #c1c1c1;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 200;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
}

.navbar--link-item-sticky {
  color: #c1c1c1;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 200;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  position: relative;
}

/* =====================================================
 * Disable the link hover color change for the nav links
 * =====================================================
 */
.navbar--link-item-sticky:hover,
.navbar--link-item:hover {
  color: #c1c1c1;
}

/* ==================================
 * Underline effect for the nav items
 * ==================================
 */
.navbar--link-item-sticky:after,
.navbar--link-item:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  width: 0;
  bottom: 0;
  background: #c1c1c1;
  height: 1px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.navbar--link-item:hover:after,
.navbar--link-item:focus:after,
.navbar--link-item:active:after,
.navbar--link-item-sticky:hover:after,
.navbar--link-item-sticky:focus:after,
.navbar--link-item-sticky:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/* =============
 * Mobile Navbar
 * =============
 */

.mobile-navbar {
  position: fixed;
  display: none;
  width: calc(100% - 1rem);
  padding: 0.5rem 0.5rem;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
}

.mobile-navbar-sticky {
  position: fixed;
  display: none;
  background: #1c1c1c;
  padding: 0.5rem 0.5rem;
  top: 0;
  left: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow: 1px 2px 1px #222222; */
  animation: moveDown 0.5s ease-in-out;
  z-index: 5;
}

.mobile-navbar--logo-holder {
  align-items: center;
}

.mobile-navbar--logo-holder:hover {
  cursor: pointer;
}

.mobile-navbar--logo {
  height: 25px;
  padding-top: 5px;
  padding-left: 6px;
  filter: grayscale(60%);
}

.dropdown-items {
  position: fixed;
  margin-top: 8px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1c1c1c;
  box-shadow: 3px 3px 3px -3px rgba(0, 0, 0, 0.06), 3px 3px 3px -3px rgba(0, 0, 0, 0.2), -3px 3px 3px -3px #999;
}

.dropdown-items button {
  background-color: #1c1c1c;
  text-transform: uppercase;
  color: #c1c1c1;
  font-size: 26px;
  margin: 0;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  outline: none; // remove ugly focus/border after click
}

.dropdown-items button:hover {
  background-color: #c1c1c1;
  color: #000000;
  cursor: pointer;
}

.dropdown-items button:active {
  background-color: #c1c1c1;
}

.active-mobile-page {
  font-weight: 700;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding-top: 5px;
  overflow: visible;
  outline: none;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #c1c1c1;
}

.hamburger-box {
  width: 50px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 3px;
  background-color: #c1c1c1;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner-sticky, .hamburger-inner-sticky::before, .hamburger-inner-sticky::after {
  background-color: #c1c1c1;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/* ==========================
 * Social Bar (always sticky)
 * ==========================
 */

.social-bar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.fa {
  // padding: 20px;
  width: inherit;
  height: inherit;
  mask-size: inherit;
  -webkit-mask-size: inherit;
  background-color: #c1c1c1;
}

.fa-original {
  // padding: 20px;
  width: inherit;
  height: inherit;
  mask-size: inherit;
  -webkit-mask-size: inherit;
}

.fa-background {
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 20px;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa-background:hover .fa {
  opacity: 0.9;
  background-color: white;
}

.fa-facebook {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/facebook-f.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/facebook-f.svg") no-repeat 50% 50%;
}

.fa-facebook-background {
  background-color: rgba(59, 89, 152, 0.3);
}
.fa-facebook-background:hover {
  background-color: rgba(59, 89, 152, 0.5);
}

.fa-twitter {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/twitter.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/twitter.svg") no-repeat 50% 50%;
}

.fa-twitter-background {
  background-color: rgba(85, 172, 238, 0.3);
}
.fa-twitter-background:hover {
  background-color: rgba(85, 172, 238, 0.5);
}

.fa-linkedin {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/linkedin-in.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/linkedin-in.svg") no-repeat 50% 50%;
}

.fa-linkedin-background {
  background-color: rgba(0, 123, 181, 0.3);
}
.fa-linkedin-background:hover {
  background-color: rgba(0, 123, 181, 0.5);
}

.fa-instagram {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/instagram.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/instagram.svg") no-repeat 50% 50%;
}

.fa-instagram-background {
  background-color: rgba(133, 30, 177, 0.3);
}
.fa-instagram-background:hover {
  background-color: rgba(133, 30, 177, 0.5);
}

.fa-tree {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/solid/tree.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/solid/tree.svg") no-repeat 50% 50%;
}

.fa-tree-background {
  background-color: rgba(13, 153, 99, 0.3);
}
.fa-tree-background:hover {
  background-color: rgba(13, 153, 99, 0.5);
}

.fa-github {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/github.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/github.svg") no-repeat 50% 50%;
}

.fa-github-background {
  background-color: rgba(9, 9, 9, 0.3);
}
.fa-github-background:hover {
  background-color: rgba(9, 9, 9, 0.5);
}

.fa-gitlab {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/gitlab.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/gitlab.svg") no-repeat 50% 50%;
}

.fa-gitlab-background {
  background-color: rgba(243, 109, 0, 0.3);
}
.fa-gitlab-background:hover {
  background-color: rgba(243, 109, 0, 0.5);
}

.fa-stack-overflow {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/stack-overflow.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/stack-overflow.svg") no-repeat 50% 50%;
}

.fa-stack-overflow-background {
  background-color: rgba(169, 169, 169, 0.3);
}
.fa-stack-overflow-background:hover {
  background-color: rgba(169, 169, 169, 0.5);
}

.fa-spotify {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/spotify-brands-solid.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/spotify-brands-solid.svg") no-repeat 50% 50%;
}

.fa-spotify-background {
  background-color: rgba(30, 215, 96, 0.3);
}
.fa-spotify-background:hover {
  background-color: rgba(30, 215, 96, 0.5);
}

.fa-mastodon {
  -webkit-mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/mastodon-brands-solid.svg") no-repeat 50% 50%;
  mask: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/mastodon-brands-solid.svg") no-repeat 50% 50%;
}

.fa-mastodon-background {
  background-color: rgba(86, 58, 204, 0.3);
}
.fa-mastodon-background:hover {
  background-color: rgba(86, 58, 204, 0.5);
}

.fa-lemmy {
  background: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/lemmy_logo.svg") no-repeat 50% 50%;
  background-size: contain;
}

.fa-lemmy-background {
  background-color: rgba(255, 255, 255, 1.0);
}
.fa-lemmy-background:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.fa-pixelfed {
  background: url("./assets/fontawesome-free-6.2.1-web/svgs/brands/pixelfed_logo.svg") no-repeat 50% 50%;
  background-size: contain;
}

.fa-pixelfed-background {
  background: linear-gradient(
    90deg,
    #ffb3ba, #ffdfba, #ffffba, #baffc9, #bae1ff, #d4bfff, #ffbaff
  );
}
.fa-pixelfed-background:hover {
  background: linear-gradient(
    90deg,
    #ff9999, #ffc28a, #ffff8a, #8fd98b, #8fbfe8, #b399e6, #e68fe6
  );
}

@keyframes social-bounce {
  0% {
    transform: scale(1, 1) translateX(0);
  }
  1% {
    transform: scale(1.1, .9) translateX(0);
  }
  3% {
    transform: scale(.9, 1.1) translateY(-100px);
  }
  5% {
    transform: scale(1.05, .95) translateX(0);
  }
  5.7% {
    transform: scale(1, 1) translateY(-7px);
  }
  6.4% {
    transform: scale(1, 1) translateX(0);
  }
  10% {
    transform: scale(1, 1) translateX(0);
  }
  100% {
  }
}

/* =====
 * About
 * =====
 */
.container {
  display: block;
  padding-left: 10rem;
  padding-right: 10rem;
}

.row {
  display: flex;
}

.box-shadow-full {
  padding: 30px 30px;
  margin-top: 50px;
  position: relative;
  background-color: #333333;
  z-index: 2;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.information {
  flex: 0 0 50%;
}

.profile {
  padding-left: 20px;
  position: relative;
  margin: auto;
}

.image-and-profile {
  display: inline-flex;
  padding: 10px 20px 10px 10px;
}

.information-skills {
  margin-top: 10px;
}

.profile-title {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}

.profile-image-container {
  display: flex;
  align-items: center;
}

.flip-card-profile {
  width: 200px !important;
  height: 200px !important;
}

.flip-card-back-profile {
  background-color: #c1c1c1 !important;
  color: #1c1c1c !important;
  font-size: inherit !important;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 20px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  transform: rotateY(180deg);
}

.profile-image {
  border-radius: 20px;
  height: 200px;
}

.all-information {
  display: inline-flex;
}

.skill-container {
  padding: 10px;
}

.skill-container h2 {
  margin-top: 0;
}

.skills {
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    display: block;
    position: relative;
    background-color: #ffffff;
    color: #333333;
    margin: 0;
    padding-left: 2px;
    border-radius: 10px;

    .collapsed & {
      width: 0 !important;
    }

    p {
      margin-left: 5px;
      font-weight: 500;
    }

    span {
      position: absolute;
      right: 5px;
      display: inline-block;
      width: 30px;
      top: 3px;
      text-align: right;
      font-weight: 500;
      color: #333333;
      font-size: 11px;
    }
  }
}

.load-skill-container {
  animation-name: load-skill;
}

.unload-skill-container {
  animation-name: unload-skill;
}

@keyframes load-skill {
  0% {
    width: 0
  }
  100% {
    width: 100%
  }
}

@keyframes unload-skill {
  0% {
    width: 100%
  }
  100% {
    width: 0
  }
}

.profile-text {
  padding: 0;
}

.profile-text h2 {
  margin-top: 0;
}

.profile-text span p {
  text-align: justify;
  text-justify: inter-word;
  font-size: 33px;
}

/* ========
 * Counters
 * ========
 */
.counters {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  box-shadow: inset 0 0 0 2000px rgba(33, 150, 243, 0.3);
}

.counters-row {
  width: 100%;
  display: flex;
  padding-top: 140px; /*TODO change that value when images are there */
}

.counters-cell {
  text-align: center;
  width: 100%;
}

.counters-element {
  display: block;
}

.counters-element p {
  color: #000000;
  font-weight: normal;
  font-size: 25px;
  margin: 0;
}

.counters-image {
  height: 100px;
}

.counters-counter {
  font-size: 42px;
  color: #000000;
}

/* ========
 * Services
 * ========
 */

.services-items {
  display: flex;
  justify-content: center;
}

.services-description {
  padding-bottom: 0;
}

.services-description-row {
  display: flex;
}

.services-description-col {
  margin: auto;
  width: 66%;
}

.services-description-item {
  text-align: center;
}

.services-description-item p {
  text-align: center;
  text-justify: inter-word;
  font-size: 33px;
}

.services-items-row {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: auto auto auto auto;
  padding-top: 0;
  padding-bottom: 30px;
}

.services-items-col {
  display: flex;
  justify-content: center;
}

.services-icon {
  height: 100px;
}

.flip-card {
  background-color: transparent;
  text-align: center;
  width: 300px;
  height: 300px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 20px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #c1c1c1;
  font-size: 33px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-front h3 {
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #333333;
}

.information .flip-card-front {
  filter: grayscale(100%);
}

.flip-card-front {
  background-color: hsl(225, 15, 70);
}

/* Style the back side */
.flip-card-back {
  background-color: hsl(225, 15, 80);;
  color: #1c1c1c;
  transform: rotateY(180deg);
}

.flip-card-back p {
  padding: 20px;
}

/* ========
 n* Projects
 * ========
 */

.projects-description {
  padding-bottom: 0;
}

.projects-description-row {
  display: flex;
}

.projects-description-col {
  margin: auto;
  width: 66%;
}

.projects-description-item {
  text-align: center;
}

.projects-description-item p {
  text-align: center;
  text-justify: inter-word;
  font-size: 33px;
}

.projects-date {
  color: #c1c1c1;
}

.projects-location {
  margin: 0;
}

.vertical-timeline-element-content {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2) !important;
  background-color: hsl(225, 15, 70) !important;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid hsl(225, 15, 70) !important;
}

.vertical-timeline::before {
  background: hsl(225, 15, 70) !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px hsl(225, 15, 70) !important;
}

.vertical-timeline-element-title,
.vertical-timeline-element-subtitle,
.projects-location {
  color: #1c1c1c !important;
}

.vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline-element-content p {
  font-weight: 200 !important;
}

.projects-more-info-trigger {
  color: #0a3c7a;
}
.projects-more-info-trigger:hover {
  color: #082240;
}

.azure-image {
  width: 100px;
  height: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* =====
 * Popup
 * =====
 */

.popup-content {
  background: #333333;
  width: 33%;
  padding: 10px;
  border-radius: 5px;
  max-height: 90%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.popup-content::-webkit-scrollbar {
  display: none;
}

.popup-content p {
  margin-top: 0;
  margin-bottom: 0;
}

.popup-content h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.projects-more-info-trigger:hover {
  cursor: pointer;
}

/* ==========
 * Blue Popup
 * ==========
 */

.blue-popup-content {
  border: 3px solid rgb(28, 28, 28);
}

.blue-popup-overlay {
  background: rgba(193, 193, 193, 0.7);
}

/* ===========
 * Green Popup
 * ===========
 */

.green-popup-content {
  border: 3px solid rgb(58, 243, 60);
}

.green-popup-overlay {
  background: rgba(58, 243, 60, 0.2);
}

/* ============
 * Yellow Popup
 * ============
 */

.yellow-popup-content {
  border: 3px solid rgb(243, 237, 50);
}

.yellow-popup-overlay {
  background: rgba(243, 237, 50, 0.2);
}

/* =========
 * Red Popup
 * =========
 */

.red-popup-content {
  border: 3px solid rgb(243, 49, 14);
}

.red-popup-overlay {
  background: rgba(243, 49, 14, 0.2);
}

/* ==========
 * Pink Popup
 * ==========
 */

.pink-popup-content {
  border: 3px solid rgb(225, 50, 243);
}

.pink-popup-overlay {
  background: rgba(225, 50, 243, 0.2);
}

/* ============
 * Purple Popup
 * ============
 */

.purple-popup-content {
  border: 3px solid rgb(149, 88, 243);
}

.purple-popup-overlay {
  background: rgba(149, 88, 243, 0.2);
}

/* ==========
 * Darkblue Popup
 * ==========
 */

.darkblue-popup-content {
  border: 3px solid rgb(45, 44, 243);
}

.darkblue-popup-overlay {
  background: rgba(45, 44, 243, 0.2);
}

.popup-scrollable {
  overflow: hidden;
}

/* =======
 * Contact
 * =======
 */
.contact {
  margin-top: 50px;
  padding-top: 10rem;
  padding-bottom: 10rem;
  background-color: #333333;
}

.contact-row {
  display: block;
}

.contact-col {
  margin: auto;
  width: 50%;
}

.contact-description {
  color: rgb(131, 131, 131);
  text-align: center;
}

.contact-description hr {
  border: 1px solid rgb(131, 131, 131);
}

.contact-description p {
  font-size: 33px;
  font-weight: 200;
  padding-bottom: 30px;
}
//.contact-description p a {
//  color: hsl(225, 100, 70);
//}
//
//.contact-description p a:hover {
//  color: hsl(225, 100, 65);
//}

.contact-description h2 {
  padding-top: 0;
  margin-top: 0;
  color: rgb(196, 196, 196);
}

.contact-form {
  padding-top: 40px;
  border-radius: 10px;
  align-content: center;
}

.contact-form input, textarea {
  font-family: inherit;
  color: rgb(196, 196, 196);
  background-color: transparent;
  width: 100%;
  border: 1px solid rgb(72, 72, 72);
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 10px;
  box-sizing: border-box; /* otherwise the inputs are longer than the div */
  -webkit-appearance: none;
}

.contact-form input:focus, textarea:focus {
  outline: none;
  box-shadow: 0 0 5px 0 hsl(225, 100, 70);
}

::placeholder {
  font-weight: 350;
  color: rgb(136, 136, 136);
}

.contact-form input[type=submit] {
  background-color: hsl(225, 30, 60);
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: normal;
  text-transform: uppercase;
  -webkit-appearance: none;
}

.contact-form input[type=submit]:hover {
  background-color: hsl(225, 70, 65);
  cursor: pointer;
}

.contact-form textarea {
  height: 100px;
}

.contact-form-alert {
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.contact-form-alert-success {
  background-color: rgba(58, 243, 60, 0.2);
  color: #333333;
}

.contact-form-alert-failure {
  background-color: rgba(243, 49, 14, 0.2);
  color: #eaeaea;
}

.contact-form-alert-close-button {
  margin-left: 15px;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.contact-form-alert-close-button-success {
  color: #333333;
}

.contact-form-alert-close-button-failure {
  color: #eaeaea;
}

.contact-form-alert-close-button-success:hover {
  color: rgb(105, 105, 105);
}

.contact-form-alert-close-button-failure:hover {
  color: #b7b7b7;
}

/* Workaround for issue with autofill colors of Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #333333 inset;
  -webkit-text-fill-color: rgb(196, 196, 196);
  caret-color: rgb(196, 196, 196);
}

.highlight {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

/* ======
 * Footer
 * ======
 */

.footer {
  background-color: #333333;
  color: rgb(131, 131, 131);
  text-align: center;
  padding-bottom: 10px;
}

.footer a {
  color: rgb(131, 131, 131);
  text-decoration: underline;
}

.footer a:hover {
  color: rgb(196, 196, 196);
}

/* =================
 * Imprint & Privacy
 * =================
 */

.imprint,
.privacy {
  padding: 20px;
}

/* =====================
 * Responsive web design
 * =====================
 */

// Smaller width than 1170px in general
@media only screen and (max-width: 1170px) {
  .projects-date {
    color: #000000;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline-element-content p {
    font-weight: 500 !important;
  }
}

// Smaller width than 2240px in general
@media only screen and (max-width: 2240px) {
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 32px !important;
  }
}

// Smaller width than 2040px in general
@media only screen and (max-width: 2055px) {
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 30px !important;
  }
}

// Smaller width than 1935px in general
@media only screen and (max-width: 1935px) {
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 28px !important;
  }
}

// Smaller width than 1670px in general
@media only screen and (max-width: 1670px) {
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 27px !important;
  }
}

// Smaller width than 1620px in general
@media only screen and (max-width: 1620px) {
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 26px !important;
  }
}

// Smaller width than 1355px in general
@media only screen and (max-width: 1355px) {
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 25px !important;
  }
}

// Smaller width than 1310px in general
@media only screen and (max-width: 1310px) {
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 24px !important;
  }
}

// Smaller width than 1750px in general
@media only screen and (max-width: 1750px) {
  .navbar--logo-holder h1 {
    font-size: 30px;
    //font-weight: 600;
  }

  .intro .intro-title {
    font-size: 36px;
  }
  .intro .intro-subtitle {
    font-size: 30px;
  }

  .container {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .flip-card-profile {
    width: 150px !important;
    height: 150px !important;
  }
  .flip-card-back-profile p span {
    font-size: 14px !important;
  }
  .profile-title span {
    font-size: 15px;
  }
  .profile-image {
    width: 150px;
    height: 150px;
  }
  .fa-background {
    width: 30px;
    height: 30px;
    // font-size: 30px;
  }

  .skill-container {
    padding-left: 0;
    padding-right: 0;
  }
  .skill-container h3 {
    font-size: 24px;
  }
  .image-and-profile {
    padding-left: 0;
  }

  .counters {
    height: 300px;
  }
  .counters-row {
    padding-top: 70px; /* middle the content empirically */
  }
  .counters-element p {
    font-size: 20px;
  }
  .counters-image {
    height: 80px;
  }
  .counters-counter {
    font-size: 30px;
  }

  .services-icon {
    height: 80px;
  }
  .flip-card-front h3 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .popup-content {
    width: 66%;
  }

  .contact {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #333333;
  }
  .contact-col {
    width: 80%;
  }
  .contact-form {
    padding-top: 10px;
  }
  .contact-description p {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .all-information {
    display: flex;
    flex-direction: column;
  }
  .fa-background {
    width: 40px;
    height: 40px;
    // font-size: 40px;
  }
}

@media only screen and (max-width: 1090px) {
  .fa-background {
    width: 30px;
    height: 30px;
    // font-size: 30px;
  }
}

// Small Desktop
@media only screen and (min-width: 1100px) and (max-width: 1750px) {
  .navbar {
    width: calc(100% - 10rem);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 5rem;
  }
  .navbar--sticky {
    padding-top: 0;
    padding-bottom: 1px;
    padding-left: 5rem;
  }
  .navbar--link-item {
    font-size: 20px;
  }
  .navbar--link-item-sticky {
    font-size: 20px;
  }
}


// Smaller width than 1450px in general
@media only screen and (max-width: 1450px) {
  .services-items-row {
    grid-row-gap: 20px;
    grid-template-columns: auto auto;
  }
}


// Smaller width than 1350px in general
@media only screen and (max-width: 1350px) {
  .projects-description-col {
    width: 66%;
  }
  .services-description-col {
    width: 66%;
  }
  .projects-description-item hr {
    width: 66%;
  }
  .services-description-item hr {
    width: 66%;
  }
}

// Smaller width than 1100px in general (tablets and mobiles)
@media only screen and (max-width: 1100px) {
  .navbar {
    display: none;
  }
  .navbar--sticky {
    display: none;
  }
  .mobile-navbar {
    display: flex;
  }
  .mobile-navbar-sticky {
    display: flex;
  }
}


// Smaller width than 950px in general (tablets and mobiles)
@media only screen and (max-width: 950px) {
  .projects-description-col {
    width: 80%;
  }
  .services-description-col {
    width: 80%;
  }

  .counters-row {
    padding-top: 90px; /* middle the content empirically */
  }
  .counters-element p {
    font-size: 18px;
  }
  .counters-image {
    height: 50px;
  }
  .counters-counter {
    font-size: 24px;
  }
}

@media only screen and (max-width: 850px) {
  .vertical-timeline-element-title {
    font-size: 26px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 24px;
  }
  .projects-location {
    font-size: 22px;
  }

  .services-items-row {
    grid-template-columns: repeat(1, 1fr);
  }
  .services-icon {
    height: 50px;
  }
  .services-items-row {
    grid-template-columns: auto;
  }
  .flip-card {
    width: 500px;
    height: 200px;
  }
}

// Smaller width than 750px in general (tablets and mobiles)
@media only screen and (max-width: 750px) {
  .vertical-timeline-element-title {
    font-size: 24px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 22px;
  }
  .projects-location {
    font-size: 20px;
  }

  .social-bar {
    right: 0;
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .counters {
    height: 200px;
  }
  .counters-row {
    padding-top: 45px; /* middle the content empirically */
  }
  .counters-element p {
    font-size: 17px;
  }

  .contact-col {
    width: 95%;
  }
}

@media only screen and (max-width: 700px) {
  .vertical-timeline-element-title {
    font-size: 22px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 20px;
  }
  .projects-location {
    font-size: 18px;
  }
}

// Smaller width than 580px in general (tablets and mobiles)
@media only screen and (max-width: 585px) {
  .vertical-timeline-element-title {
    font-size: 20px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 18px;
  }
  .projects-location {
    font-size: 16px;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 26px;
  }
  h5 {
    font-size: 24px;
  }

  .dropdown-items button {
    font-size: 22px;
  }
  .hamburger-box {
    width: 30px;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
  }

  .intro .intro-title {
    font-size: 30px;
  }
  .intro .intro-subtitle {
    font-size: 22px;
  }

  .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .box-shadow-full {
    padding: 15px 15px;
  }
  .skill-container h3 {
    font-size: 20px;
  }
  .image-and-profile {
    display: inline-flex;
    padding-left: 0;
  }
  .profile-image img {
    height: 110px;
  }
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 22px !important;
  }
  .profile {
    position: unset;
  }
  .profile-title {
    font-size: 17px;
  }
  .profile-center p {
    width: auto;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 78px;
    text-indent: -78px;
  }

  .skills {
    li {
      span {
        position: absolute;
        right: 5px;
        display: inline-flex;
        width: 30px;
        top: 3px;
        text-align: right;
        font-weight: normal;
        color: #fff;
        font-size: 11px;
      }
    }
  }

  .flip-card {
    width: 400px;
  }
}

// Smaller width than 530px in general (tablets and mobiles)
@media only screen and (max-width: 530px) {
  .fa-background {
    width: 20px;
    height: 20px;
    // font-size: 20px;
  }
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 21px !important;
  }
}

// Smaller width than 490px in general (tablets and mobiles)
@media only screen and (max-width: 490px) {
  .vertical-timeline-element-title {
    font-size: 18px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 16px;
  }
  .projects-location {
    font-size: 14px;
  }

  .flip-card-profile {
    width: 130px !important;
    height: 130px !important;
  }
  .flip-card-back-profile {
    line-height: 1.0;
  }
  .flip-card-back-profile p span {
    font-size: 12px !important;
  }
  .profile-image {
    width: 130px;
    height: 130px;
  }
  .fa-background {
    width: 15px;
    height: 15px;
    // font-size: 15px;
  }
}

// Smaller width than 480px in general (tablets and mobiles)
@media only screen and (max-width: 480px) {
  .flip-card {
    width: 300px;
    height: 180px;
  }
  .flip-card-front h3 {
    font-size: 22px;
  }
}

// Smaller width than 450px in general (tablets and mobiles)
@media only screen and (max-width: 450px) {
  h1 {
    text-align: center;
  }
  h2 {
    text-align: center;
  }
  h3 {
    text-align: center;
  }
  h4 {
    text-align: center;
  }
  h5 {
    text-align: center;
  }

  .vertical-timeline-element-content h1,
  .vertical-timeline-element-content h2,
  .vertical-timeline-element-content h3,
  .vertical-timeline-element-content h4,
  .vertical-timeline-element-content h5 {
    text-align: left;
  }

  .skill-container {
    padding: 10px;
  }

  .information-text {
    padding: 10px;
  }

  .image-and-profile {
    display: block;
    padding-left: 10px;
  }
  .profile-image-container {
    margin: auto;
    padding-bottom: 10px;
  }
  .flip-card-profile {
    margin: auto;
  }
  .profile {
    padding-left: 0;
    width: 100%;
    justify-content: space-between !important;
    align-content: center !important;
  }
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 20px !important;
  }
}

// Smaller width than 385px in general (tablets and mobiles)
@media only screen and (max-width: 385px) {
  .profile-image img {
    height: 130px;
  }
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 19px !important;
  }
}

// Smaller width than 365px in general (tablets and mobiles)
@media only screen and (max-width: 365px) {
  .invisible {
    display: none;
  }

  .vertical-timeline-element-title {
    font-size: 16px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 14px;
  }
  .projects-location {
    font-size: 12px;
  }

  .dropdown-items button {
    font-size: 20px;
  }
  .profile-text span p,
  .services-description-item p,
  .projects-description-item p,
  .flip-card-back p,
  .contact-description p {
    font-size: 17px !important;
  }

  .profile-image img {
    margin: auto;
    padding-bottom: 10px;
  }
  .image-and-profile {
    display: block;
    padding-left: 10px;
  }
  .profile-center {
    text-align: center;
  }
  .profile-center p {
    margin: 0;
    padding-left: 0;
    text-indent: 0;
  }
  .profile-center p span {
    display: block;
  }
  .profile-title {
    display: none !important;
  }
  .skill-container h3 {
    font-weight: 500;
  }

  .counters-row {
    padding-top: 47px; /* middle the content empirically */
  }
  .counters-element p {
    font-size: 15px;
  }
  .counters-image {
    height: 30px;
  }

  .flip-card {
    width: 240px;
    height: 160px;
  }
  .flip-card-front h3 {
    font-size: 20px;
  }
}

// Smaller width than 300px in general (tablets and mobiles)
@media only screen and (max-width: 300px) {
  .vertical-timeline-element-title {
    font-size: 18px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 16px;
  }
  .projects-location {
    font-size: 14px;
  }

  .flip-card {
    width:180px;
    height: 140px;
  }
  .flip-card-front h3 {
    font-size: 17px;
  }
}

@media only screen and (max-width: 250px) {
  .vertical-timeline-element-title {
    font-size: 14px;
  }
  .vertical-timeline-element-subtitle {
    font-size: 12px;
  }
  .projects-location {
    font-size: 10px;
  }
}