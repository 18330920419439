body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* nunito-200 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-300 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-500 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-900 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-200italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-200italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-300italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 300;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-300italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-500italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-500italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-600italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-600italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-700italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-700italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-800italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 800;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-800italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-900italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 900;
  src: url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('./assets/fonts/nunito-v25-latin/nunito-v25-latin-900italic.svg#Nunito') format('svg'); /* Legacy iOS */
}
